import * as React from 'react';
import { useState, useRef } from 'react';
import pose from 'react-pose';
import { useZone } from 'use-zone-breakpoints';
import styled from '@emotion/styled';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { FlexItem } from '../grid';
import { ArtistName } from './artist-name';
import { ZONE_IMG_SIZE } from '../../config';
import { Link } from '../wrapper-lang';
import { shouldSquare } from '../grid/utils';
import { mqs } from '../style-utils';
const PoseBoxer = pose.div({
    init: {
        opacity: 0,
        flip: true,
    },
    enter: {
        opacity: 1,
        y: 0,
        width: '100%',
        height: '100%',
        transition: {
            duration: 500,
            ease: 'easeOut',
        },
        flip: true,
        delay: 300,
    },
    exit: {
        opacity: ({ aniState }) => aniState.opacity,
        position: ({ aniState }) => aniState.position,
        top: ({ aniState }) => aniState.top,
        left: ({ aniState }) => aniState.left,
        width: ({ aniState }) => aniState.width,
        height: ({ aniState }) => aniState.height,
        transition: {
            duration: 525,
            ease: [0.08, 0.69, 0.2, 0.99],
        },
        flip: true,
    },
    flip: {
        transition: {
            duration: 0,
            type: 'tween',
        },
    },
});
const ImageBoxer = styled(PoseBoxer) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  & .gatsby-image-wrapper > picture > img {
    transform: scale(1);
    transition: transform 0.3s ease !important;
    &:hover {
      transform: scale(1.02);
    }
  }
`;
const getActiveProps = ({ zone, isActive, widthSize, leftPos }) => {
    return isActive
        ? {
            opacity: 1,
            position: 'fixed',
            top: '8rem',
            left: leftPos,
            width: `${(ZONE_IMG_SIZE[zone] * widthSize) / 100}px`,
            height: `${(ZONE_IMG_SIZE[zone] * widthSize) / 200}px`,
        }
        : {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        };
};
const PoseSizer = pose.article({
    enter: {
        opacity: 1,
        applyAtBegin: {
            visibility: 'visible',
        },
    },
    exit: {
        opacity: ({ isActive }) => (isActive ? 1 : 0),
        applyAtBegin: {
            visibility: 'hidden',
        },
    },
});
const ImageSizer = styled(PoseSizer) `
  position: relative;
  width: 100%;
  /* background: #ebeced; */
  padding-bottom: ${({ begin, end, gridSize }) => shouldSquare({ begin, end, gridSize }) ? 100 : 50}%;
`;
const StyledArtistBlock = styled(FlexItem) `
  margin-bottom: 1.5rem;

  ${mqs[0]} {
    margin-bottom: 3rem;
  }

  ${mqs[1]} {
    margin-bottom: 3rem;
  }

  article,
  img,
  .image-boxer {
    width: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover ${ImageSizer} h1,
  &:hover ${ImageSizer} h2 {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const ArtistBlock = ({ artist, begin, end, gridSize, widthSize, leftPos, lang, index, }) => {
    const { fields: { slug }, nameEn, nameKo, nameJa, nameZh, profilePhoto, } = artist;
    const square = profilePhoto.squareFile.childImageSharp.fluid;
    const landscape = profilePhoto.landscapeFile.childImageSharp.fluid;
    const isSquare = shouldSquare({ begin, end, gridSize });
    const [isActive, setActive] = useState(false);
    const zone = useZone();
    const ref = useRef(null);
    // const imgRef = useRef<HTMLImageElement>(null)
    const [mustLandscape, setLandscape] = useState(false);
    const displayImage = mustLandscape ? landscape : isSquare ? square : landscape;
    const onClick = () => {
        setLandscape(true);
    };
    const onMouseOver = () => {
        const $parent = ref.current.closest('.artists-list');
        $parent.classList.add('is-hovering');
        setActive(true);
    };
    const onMouseLeave = () => {
        const $parent = ref.current.closest('.artists-list');
        $parent.classList.remove('is-hovering');
        setActive(false);
    };
    return (<StyledArtistBlock ref={ref} onClick={onClick} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} 
    // onClick={onClick}
    begin={begin} end={end} gridSize={gridSize}>
      <Link to={slug}>
        <ImageSizer {...{ begin, end, gridSize }} isActive={isActive}>
          <ArtistName {...{
        lang,
        begin,
        end,
        gridSize,
        nameEn,
        nameKo,
        nameJa,
        nameZh,
    }}/>
          <ImageBoxer key={index} initialPose={'init'} pose={'enter'} aniState={getActiveProps({ isActive, zone, widthSize, leftPos })}>
            <GatsbyImage style={{
        width: '100%',
        height: '100%',
        background: '#ebeced',
    }} fadeIn={true} 
    // @ts-ignore
    fluid={displayImage}/>
          </ImageBoxer>
        </ImageSizer>
      </Link>
    </StyledArtistBlock>);
};
