import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import pose from 'react-pose';
import { mqs } from '../style-utils';
import { getTextDirection } from '../grid/utils';
const Transition = pose.div({
    enter: { opacity: 1, delay: 100 },
    exit: { opacity: 0, delay: 100 },
});
const leftText = css `
  position: absolute;
  bottom: -0.25rem;
  left: -0.75rem;
  transform: translateX(-100%);
  text-align: right;
  h1,
  h2 {
    transform: translateX(0.25rem);
  }
`;
const rightText = css `
  position: absolute;
  bottom: -0.25rem;
  right: -0.75rem;
  transform: translateX(100%);
  text-align: left;
  h1,
  h2 {
    transform: translateX(-0.25rem);
  }
`;
const bottomText = css `
  position: absolute;
  bottom: -0.5rem;
  right: 1rem;
  transform: translateY(100%);
  text-align: right;

  h1,
  h2 {
    display: inline-block;
    padding-left: 0.5rem;
    opacity: 0.8;
  }
`;
const TEXT_DIRECTION = {
    left: leftText,
    right: rightText,
    bottom: bottomText,
};
const StyledArtistName = styled(Transition) `
  color: ${({ theme }) => theme.fg};
  font-size: 1rem;

  ${mqs[0]} {
    max-width: calc(50% - 1rem);
    font-size: 0.75rem;
  }

  ${mqs[1]} {
    max-width: calc(50% - 1rem);
    font-size: 0.75rem;
  }

  ${mqs[2]} {
    font-size: 0.8rem;
  }

  ${props => TEXT_DIRECTION[getTextDirection(props)]}

  h1, h2 {
    line-height: 1.2;
    opacity: 0.7;
    transition: all 0.3s ease;
    margin-bottom: 0.25rem;
  }

  h2 {
    opacity: 0.5;
    transition-delay: 50ms;
  }
`;
export const ArtistName = ({ lang, nameKo, nameEn, nameJa, nameZh, ...props }) => {
    const name = {
        ko: nameKo,
        en: nameEn,
        ja: nameJa,
        zh: nameZh,
    };
    let nameTop = lang === 'en' || !name[lang] ? nameKo : name[lang];
    return (<StyledArtistName {...props}>
      <h1>{nameTop}</h1>
      <h2>{name.en}</h2>
    </StyledArtistName>);
};
